.booking-table-container {
  width: 100%;
  height: 100%;
  /* overflow: scroll; */
  text-align: center;
  border: 2px solid green;
  /* border-right: 0px; */
  border-start-end-radius: 5px;
  /* border-radius: 5px; */

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.booking-table-container-wide {
  width: 100%;
  height: 100%;
  /* overflow: scroll; */
  text-align: center;
  /* border: 2px solid green; */
  border-left: 0px;
  border-start-end-radius: 5px;
  /* border-radius: 5px; */
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

}

.booking-table-container-small {
  width: 100%;
  height: 100%;
  /* overflow: scroll; */
  text-align: center;
  border: 2px solid green;
  border-right: 0px;
  border-start-end-radius: 5px;
  /* border-radius: 5px; */
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

}

/* .booking-table-container-small,
.booking-table-container-wide {
  max-height: 430px;
  overflow-y: auto;
} */
/* .scrollable-container {
  overflow: auto;
  max-height: 400px;
} */
.booking-table-container-small table,
.booking-table-container-wide table {
  border: 2px solid green;
  
}

.scrollable-container {
  overflow-y: auto;
  max-height:auto; 
  border: 2px solid green; 
  
}





.booking-td,
.booking-th {
  white-space: nowrap;
  padding: 10px 20px;
  font-family: Arial;
  border: 1px solid rgb(40, 53, 63);
}

.booking-th-options {
  white-space: nowrap;
  padding: 3px;
  position: sticky;
  /* margin: 3px; */
  font-family: Arial;
  border-bottom: 1px solid rgb(40, 53, 63);
  border-top: 3px solid#535c78;
  border-left: 2px solid#535c78;
  /* height:10px; */
}

.booking-tr-th-child-one {
  position: sticky;
  width: 200px;
  left: 0;
  z-index: 10;
  background: #161d33;
}
.booking-td-th-child-one {
  position: sticky;
  width: 170px;
  left: 0;
  z-index: 10;
  background: #192039;
 
}

.booking-tr-th-child-two {
  overflow: hidden;
  position: sticky;
  max-width: 186px;
  left: 151px;
  z-index: 10;
  background: #535c78;
}
.booking-td-th-child-two {
  overflow: hidden;
  position: sticky;
  max-width: 186px;
  left: 151px;
  z-index: 10;
  background: #161d33;
}

.booking-tr-th-child-three {
  overflow: hidden;
  position: sticky;
  max-width: 190px;
  left: 324px;
  z-index: 10;
  background: #535c78;
}

.groupSearch-tr-th-child-three-HR {
  /* overflow: hidden;
  position: sticky;
  max-width: 190px;
  left: 324px; */
  z-index: 10;
  background: #535c78;
}
.booking-td-th-child-three {
  overflow: hidden;
  position: sticky;
  max-width: 190px;
  left: 324px;
  z-index: 10;
  background: #161d33;
}

.booking-container {

  position: relative;

}

.booking-position-sticky {
  position: sticky;
  top: 0;
  z-index: 100; 
 
}

 
.groupSearch-td-th-child-three-HR {

  z-index: 10;
  background: #161d33;
}

.booking-tr-th-child-one {
  z-index: 11;
}

.booking-tr-th-child-two {
  z-index: 12;
}

.booking-tr-th-child-three {
  z-index: 13;
}

.booking-tr-th-child-four {
  z-index: 14;
  background: #161d33;
}

.booking-tr-th {
  position: sticky;
  top: 0;
  z-index: 9;

}

td.last {
  min-width: 100px;

  white-space: nowrap;
  overflow-x: auto;
}

.groupSearch-th {
  background: #161d33;
}
.groupSearch-td {
  background: #161d33;
}

/* white:---------------------------------------------------------------------------------- */
.w-groupSearch-th {
  background: #f5f5f6;
}

.w-groupSearch-td {
  background: #161d33;
}

.w-booking-th-options {
  white-space: nowrap;
  padding: 3px;
  /* margin: 3px; */
  font-family: Arial;
  

  border-bottom: 1px solid #cacaca;
  border-top: 3px solid #cacaca;
  border-left: 2px solid #cacaca;
  border-right: 3px solid #cacaca;
  background: #f5f5f6 !important;
}

.w-booking-td,
.w-booking-th {
  white-space: nowrap;
  padding: 10px 20px;
  font-family: Arial;
  border: 1px solid rgb(40, 53, 63);
}

.w-booking-tr-th-child-one {
  position: sticky;
  width: 100%;
  left: 0;
  z-index: 10;
  background: #f5f5f6;
}
.w-booking-td-th-child-one {
  position: sticky;
  width: 100%;
  left: 0;
  z-index: 10;
  background: #f5f5f6;
}

.w-booking-tr-th-child-two {
  overflow: hidden;
  position: sticky;
  max-width: 186px;
  left: 151px;
  z-index: 10;
  background: #f5f5f6;
}
.w-booking-td-th-child-two {
  overflow: hidden;
  position: sticky;
  max-width: 186px;
  left: 151px;
  z-index: 10;
  background: #f5f5f6;
}

.w-booking-tr-th-child-three {
  overflow: hidden;
  position: sticky;
  max-width: 190px;
  left: 324px;
  background: #f5f5f6;
}

.w-groupSearch-tr-th-child-three-HR {
  /* overflow: hidden;
  position: sticky;
  max-width: 190px;
  left: 324px; */
  z-index: 10;
  background: #f5f5f6;
}

.w-booking-td-th-child-three {
  overflow: hidden;
  position: sticky;
  max-width: 190px;
  left: 324px;
  z-index: 10;
  background: #f5f5f6;
}

.w-groupSearch-td-th-child-three-HR {
  /* overflow: hidden;
  position: sticky;
  max-width: 190px;
  left: 324px; */
  z-index: 10;
  background: #f5f5f6;
}

.w-booking-tr-th-child-one {
  z-index: 11;
}

.w-booking-tr-th-child-two {
  z-index: 12;
}

.w-booking-tr-th-child-three {
  z-index: 13;
}

.w-booking-tr-th-child-four {
  z-index: 14;
  
}

.w-booking-tr-th {
  position: sticky;
  top: 0;
  z-index: 9;
  background: #ffffff;
}


.booking-table-container {
  overflow: auto;
}
/* important */
.booking-tr-th {
  position: sticky;
  top: 0;

  z-index: 10;
}
/* important */

.booking-td-th-child-one,
.booking-td-th-child-two,
.booking-td-th-child-three {
  position: sticky;


  z-index: 9;
}

.small-cell {
  padding: 5px; /* Reduces the padding */
  font-size: 12px; /* Makes the text smaller */
}

.small-cell-header {
  height: 8px !important;
  line-height: 8px !important;
  padding: 2px !important;
  font-size: 10px !important;
}


.scrollable-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}

.custom-table {
  border-collapse: collapse;
  width: 100%;
  font-size: 0.9rem;
  background: #f9f9f9;
}

.custom-table thead {
  background-color: #333;
  color: #fff;
  position: sticky;
  top: 0;
}

.header-icon {
  text-align: center;
  padding: 10px;
}

.header-day {
  text-align: center;
  color: #555;
}

.row-item {
  transition: background 0.3s ease;
}

.row-item:hover {
  background-color: #eef2f7;
}


.icon-group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height:5px;
}

.icon-action {
  font-size: 1rem;
  cursor: pointer;
}

.day-cell {
  text-align: center;
  padding: 5px;
  cursor: pointer;
}

.bg-available {
  background-color: #d4edda;
  color: #155724;
}

.bg-vacation {
  background-color: #f8d7da;
  color: #721c24;
}

.badge {
  display: inline-block;
  margin: 2px;
  padding: 3px 5px;
  font-size: 0.75rem;
  border-radius: 3px;
}

.badge-like {
  background-color: #ffe6e6;
  color: #ff255c;
}

.badge-dislike {
  background-color: #e6f7ff;
  color: #00b4ff;
}

.table-head {
    background-color: #999999; /* Ash-gray color */
    color: #000; /* Optional: set text color for contrast */
    position: "sticky";
    top: 0;
    
}

.table-head th {
    padding: 2px; /* Optional: add some padding */
    border: 1px solid #ccc; /* Optional: add borders */
    background-color: #999999;

}
.header-icon-candidate {
  width: 200px;
  text-align: center; /* Optional: for alignment */
}

.option-icon-candidate {
  width: 200px;
  text-align: center; /* Optional: for alignment */
}

.heart-icon-candidate{
  width: 70px;
  text-align: center; /* Optional: for alignment */
}
.heart-broken-icon-candidate{
  width: 70px;
  text-align: center; /* Optional: for alignment */
}

.row-item {
  height: 10px; /* Uniform row height */
}
.row-item > td {
  vertical-align: middle; /* Center content vertically */
}
